
import { Component, Mixins } from 'vue-property-decorator';
import mixinInform from '@/mixins/mixinInform';
import header from '@/vuex/header';

@Component({
  components: {
  }
})
export default class Spmenu extends Mixins(mixinInform) {
  open_notification_dialog(notification_id) {
    this.m.setTmpObjects({name: 'notification_id', val: notification_id});
    this.m.openDialog({name: 'inform_detail'});
  }

  open_admin_notification_dialog(notification_id) {
    this.m.setTmpObjects({name: 'admin_notification_id', val: notification_id});
    this.m.openDialog({name: 'inform_admin_detail'});
  }

  open_system_notification_dialog(notification_id) {
    this.m.setTmpObjects({name: 'system_notification_id', val: notification_id});
    this.m.openDialog({name: 'inform_system_detail'});
  }
}

