import { Component, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins'
import {makeMixin, makeUtilMixin} from '@/mixins/Descriptor';
import vuex_data from '@/vuex/vuex_data';
import vuex_manager from '@/vuex/vuex_manager';
import {makeTableMixin, dataDescript, validateDescript} from '@/mixins/Descriptor';
import * as util from '@/util';

const tableDescriptor = {
  manager: vuex_manager.notification_manager,
  data: {
    source: 'header',
    key: 'informs',
    default_sort_key: [
      {key: 'is_new', keys: ['data', 'is_new'], sort_mode: 'desc'},
      {key: 'datetime', keys: ['data', 'datetime'], sort_mode: 'desc'},
    ],
  },
}

@Component
export default class mixinNotification extends Mixins(makeTableMixin(tableDescriptor), utilMixins) {
}
