
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import header from "@/vuex/header";
import InformSp from "@/components/inform/Sp.vue";

@Component({
  components: {
    InformSp,
  }
})
export default class Header extends Mixins(utilMixins) {
  
}

